var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      ref: "payInvoiceModal",
      staticClass: "modal-xl",
      attrs: {
        id: "payInvoiceModal",
        title: "Payment",
        "title-tag": "h3",
        centered: "",
        size: "xl",
      },
      on: { hide: _vm.unsetOnClose },
      scopedSlots: _vm._u([
        {
          key: "modal-footer",
          fn: function () {
            return [
              _vm.paymentLoading
                ? _c("ct-centered-spinner")
                : _c(
                    "b-button",
                    {
                      staticClass: "submit-button",
                      attrs: {
                        disabled: !_vm.valid,
                        "aria-label": "Submit Button",
                        variant: "primary",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.pay()
                        },
                      },
                    },
                    [_vm._v("\n      Submit Payment\n    ")]
                  ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c(
        "b-container",
        { staticClass: "pay-invoices-modal", attrs: { fluid: "" } },
        [
          _c("payment-method-options", {
            attrs: {
              total: _vm.selectionTotal,
              "payment-method-errors": _vm.paymentMethodErrors,
            },
          }),
          _c(
            "b-form-group",
            [
              _c(
                "b-form-checkbox",
                {
                  staticClass: "float-right",
                  attrs: { name: "check-button" },
                  model: {
                    value: _vm.manualRemittance,
                    callback: function ($$v) {
                      _vm.manualRemittance = $$v
                    },
                    expression: "manualRemittance",
                  },
                },
                [_vm._v("\n        Manual Remittance\n      ")]
              ),
            ],
            1
          ),
          _c("h5", [_vm._v("Invoices")]),
          _vm._l(_vm.invoices, function (invoice) {
            return _c(
              "b-list-group",
              { key: invoice.id },
              [
                _c(
                  "b-list-group-item",
                  {
                    staticClass:
                      "d-flex justify-content-between align-items-center",
                    attrs: { href: "#", target: "_blank" },
                    on: {
                      click: function ($event) {
                        return _vm.toggleCollapseFor(invoice.id)
                      },
                    },
                  },
                  [
                    _c("div", [
                      _c(
                        "p",
                        { staticClass: "mb-1", attrs: { variant: "link" } },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(invoice.company_name) +
                              "\n          "
                          ),
                        ]
                      ),
                      _c("small", { staticClass: "float-left" }, [
                        _vm._v("#" + _vm._s(invoice.invoice_number)),
                      ]),
                    ]),
                    _c("span", [
                      _c("strong", [
                        _vm._v(_vm._s(_vm._f("currency")(invoice.total))),
                      ]),
                    ]),
                  ]
                ),
                _c(
                  "b-collapse",
                  { attrs: { id: _vm.collapseIdFor(invoice.id) } },
                  [
                    _c("b-table", {
                      staticClass: "pl-lg-3",
                      attrs: {
                        items: invoice.invoice_line_items,
                        fields: _vm.invoiceFields,
                        striped: "",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            )
          }),
          _c("br"),
          _c("div", { staticClass: "text-right" }, [
            _c("h5", [
              _c("strong", [_vm._v("Total:")]),
              _vm._v(
                "\n        " +
                  _vm._s(_vm._f("currency")(_vm.selectionTotal)) +
                  "\n      "
              ),
            ]),
          ]),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }