<template>
  <b-modal
    id="upsellMailForwardingPayModal"
    ref="upsellMailForwardingPayModal"
    class="modal-xl"
    title="Payment"
    title-tag="h3"
    centered
    size="xl"
  >
    <b-container fluid>
      <payment-method-options
        :total="price"
        :payment-method-errors="paymentMethodErrors"
      />
      <b-form>
        <div class="container">
          <h5>
            Invoices
          </h5>
          <b-list-group>
            <b-list-group-item class="d-flex justify-content-between align-items-center">
              <span class="mb-1">
                {{ companyName }}
              </span>
              <strong>${{ price }}</strong>
            </b-list-group-item>
            <b-table :items="items" :fields="invoiceFields" striped />
          </b-list-group>
          <br>
          <div class="text-right">
            <h5>
              <strong>Total:</strong>
              ${{ price }}
            </h5>
          </div>
        </div>
      </b-form>
    </b-container>
    <template v-slot:modal-footer>
      <div v-if="paymentLoading" class="d-flex flex-row justify-content-center" style="width: 100%">
        <ct-centered-spinner />
      </div>
      <b-button
        v-else
        :disabled="!valid"
        variant="primary"
        aria-label="submit payment button"
        @click="pay"
      >
        Submit Payment
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import CtCenteredSpinner from './shared/CtCenteredSpinner'
import PaymentMethodOptions from '@/components/Payments/PaymentMethodOptions'
import { paymentMethodsMixin } from '@/mixins/paymentMethodsMixin'

export default {
  name: 'UpsellMailForwardingPayModal',
  components: {
    CtCenteredSpinner,
    PaymentMethodOptions,
  },
  mixins: [paymentMethodsMixin],
  props: {
    bus: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      companyName: '',
      invoiceFields: ['description', 'quantity', 'price'],
      paymentLoading: false,
      paymentMethodErrors: null,
      price: 0,
      payable: null,
    }
  },
  computed: {
    ...mapGetters('checkout', ['findProduct']),
    items() {
      return [{ price: '$'+this.price, description: this.description, quantity: 1 }]
    },
    valid() {
      return this.selectedPaymentMethod && this.agreementAccepted
    },
  },
  async mounted() {
    this.bus.$on('showUpsellMailForwardingPayModal', this.open)
  },
  methods: {
    ...mapActions('checkout', ['checkout', 'resetState', 'setupContext', 'addToCart', 'clearCart']),
    open(params) {
      this.$refs.upsellMailForwardingPayModal.show()
      this.companyName = params['companyName']
      this.document    = params['document']
      this.product     = params['product']
      this.price       = this.product.price
      this.description = this.product.name
    },
    async pay() {
      this.paymentLoading = true
      let response

      // TODO: hack to avoid being cleared by resetState
      this.payable = this.selectedPaymentMethod

      await this.resetState()

      // TODO: hack to re-assign after resetState
      this.setSelectedPaymentMethod(this.payable)

      await this.setupContext( { companyId: this.document.company_id } )
      await this.clearCart()
      const addedToCart = await this.addToCart(this.product)

      if (addedToCart) {
        this.verifyOrderCheckoutButtonOverrides = true
        this.origin = 'hire-us'
        response = await this.checkout(this)
      }

      if (response && response.invoice) {
        this.$emit('completed')
      } else {
        this.paymentMethodErrors = "Something went wrong! Please try again or contact support."
      }
      this.paymentLoading = false
    },
  },
}
</script>
