var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      ref: "upsellMailForwardingPayModal",
      staticClass: "modal-xl",
      attrs: {
        id: "upsellMailForwardingPayModal",
        title: "Payment",
        "title-tag": "h3",
        centered: "",
        size: "xl",
      },
      scopedSlots: _vm._u([
        {
          key: "modal-footer",
          fn: function () {
            return [
              _vm.paymentLoading
                ? _c(
                    "div",
                    {
                      staticClass: "d-flex flex-row justify-content-center",
                      staticStyle: { width: "100%" },
                    },
                    [_c("ct-centered-spinner")],
                    1
                  )
                : _c(
                    "b-button",
                    {
                      attrs: {
                        disabled: !_vm.valid,
                        variant: "primary",
                        "aria-label": "submit payment button",
                      },
                      on: { click: _vm.pay },
                    },
                    [_vm._v("\n      Submit Payment\n    ")]
                  ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c(
        "b-container",
        { attrs: { fluid: "" } },
        [
          _c("payment-method-options", {
            attrs: {
              total: _vm.price,
              "payment-method-errors": _vm.paymentMethodErrors,
            },
          }),
          _c("b-form", [
            _c(
              "div",
              { staticClass: "container" },
              [
                _c("h5", [_vm._v("\n          Invoices\n        ")]),
                _c(
                  "b-list-group",
                  [
                    _c(
                      "b-list-group-item",
                      {
                        staticClass:
                          "d-flex justify-content-between align-items-center",
                      },
                      [
                        _c("span", { staticClass: "mb-1" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.companyName) +
                              "\n            "
                          ),
                        ]),
                        _c("strong", [_vm._v("$" + _vm._s(_vm.price))]),
                      ]
                    ),
                    _c("b-table", {
                      attrs: {
                        items: _vm.items,
                        fields: _vm.invoiceFields,
                        striped: "",
                      },
                    }),
                  ],
                  1
                ),
                _c("br"),
                _c("div", { staticClass: "text-right" }, [
                  _c("h5", [
                    _c("strong", [_vm._v("Total:")]),
                    _vm._v(
                      "\n            $" + _vm._s(_vm.price) + "\n          "
                    ),
                  ]),
                ]),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }